<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10 " style="min-height:600px;">
               <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2 ml-2"
                    >
                      <v-row>
                        <v-col cols="4">
                          <v-select
                            v-model="select"
                            :items="brands"
                            item-text="terminal_name"
                            @change="productselect(select)"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            v-model="UserSelect"
                            :items="UserItem"
                            item-text="username"
                            return-object
                            @change="UserSelectAction(UserSelect)"
                          ></v-select>
                        </v-col>
                        <v-col cols="4"></v-col>
                      </v-row>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
              <v-data-table
                :headers="headers"
                :items="terminalData"
                sort-by="id"
                class="elevation-1"
                style="background: #f5f5f5; font-size: 10em"
                v-if="terminalData.length>0"
              >
               
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    v-if="item.admin_status == 'Confirmed'"
                    color="primary"
                    style="padding-left: 0px !important"
                    text
                    @click="viewInvoice(item)"
                    >Invoice</v-btn
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" outlined centered color="black">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    snackbar: "",
    headers: [
      {
        text: " Order ID",
        value: "id",
      },
       { text: "Order Date", value: "ordered_date" },
   
      { text: "Total Products", value: "num_items" },
      { text: "Price", value: "grand_total" },
        { text: "Dalivery Status", value: "delivery_status", sortable: false },
      { text: "Warehouse/Shop", value: "storename" },
      { text: "Invoice", value: "actions", sortable: false },
    ],
    // select: { terminal_name: item[0].terminal_name },
    // items: [
    //   { state: "Terminal A" },
    //   { state: "Terminal B" },
    //   { state: "Terminal C" },
    //   { state: "Terminal D" },

    // ],
    // UserSelect:{ username: "All User" },
    UserItem: [],
    terminalData: [],
    select: {},
    UserSelect: {},

    brands: [],
    beforeob: {},
    beforedata: [],
    contact: {},

    text: "",
    snackbar: false,
  }),

  methods: {
    initialize() {
      axios.get("productdetails/terminal_list/").then((response) => {
        this.brands = response.data.data;
        // console.log("this is list" , this.brands)
        //this.select=this.brands[0]
      });
    },

    productselect() {
      // console.log("this is for test" , this.select.id)
      axios
        .get(`Cart/get_terminal_users/${this.select.id}/`)
        .then((response) => {
          this.UserItem = response.data.data;
        });
    },
    UserSelectAction() {
      // console.log("test" , this.select.id , this.UserSelect.id)
      axios
        .get(`Cart/pos_report/${this.select.id}/${this.UserSelect.id}/`)
        .then((response) => {
          this.beforedata = response.data.order_data;
      
          if (this.beforedata.length) {
          
              this.beforedata.forEach(element => {
                  element.storename=response.data.store_data.store_name
              })
            this.terminalData=this.beforedata;
           
          }
        });
    },
    viewInvoice(item) {
      this.$router.push({
        path: "/supportdashboard/pages/pos_invoice_updated",
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.initialize();
    // this.productselect();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>